<template>
  <b-card-actions
    title="Billing"
    tdesc="Invoices And Payments"
    action-collapse
    class="user-custom-card user-billing"
  >
    <b-tabs
      pills
      nav-class=""
    >
      <div class="clearfix" />
      <billing-invoices
        :invoices="invoices"
        @removeCreditNote="removeCreditNote"
      />
      <billing-payments :payments="payments" />
      <billing-custom-interventions
        :ci="ci"
        :user="user"
      />
      <billing-custom-recurring-services
        :crs="crs"
        :user="user"
      />
    </b-tabs>
  </b-card-actions>
</template>

<script>
import {
  BTabs,
} from 'bootstrap-vue'
import BCardActions from '@/layouts/components/UserDetailCard.vue'
import BillingInvoices from './BillingInvoices.vue'
import BillingPayments from './BillingPayments.vue'
import BillingCustomInterventions from './BillingCustomInterventions.vue'
import BillingCustomRecurringServices from './BillingCustomRecurringServices.vue'

export default {
  components: {
    BCardActions,
    BTabs,
    'billing-invoices': BillingInvoices,
    'billing-payments': BillingPayments,
    'billing-custom-interventions': BillingCustomInterventions,
    'billing-custom-recurring-services': BillingCustomRecurringServices,
  },
  props: {
    invoices: {
      type: Array,
      default: null,
    },
    payments: {
      type: Array,
      default: null,
    },
    ci: {
      type: Array,
      default: null,
    },
    crs: {
      type: Array,
      default: null,
    },
    user: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      userInvoices: null,
      userPayments: null,
    }
  },
  created() {
    if (this.invoices) this.userInvoices = this.invoices
    if (this.payments) this.userPayments = this.payments
  },
  methods: {
    removeCreditNote(creditNote) {
      this.$emit('removeCreditNote', creditNote)
    },
  },
}
</script>

<style lang="scss">
  .user-billing {
    .nav.nav-pills {
      float: left !important;
    }
  }
</style>
