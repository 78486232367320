<template>
  <b-card
    no-body
    class="border-primary"
  >
    <div v-if="user && billing">
      <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25 mt-1">
        <h4 class="mb-0">
          Package
        </h4>
        <b-badge
          v-if="packageType===5003 || packageType===6003"
          variant="light-primary cfs-16"
        >
          iGaming {{ titleCase(getPackage(billing.package).name) }} Plan
        </b-badge>
        <b-badge
          v-else
          variant="light-primary cfs-16"
        >
          {{ titleCase(getPackage(billing.package).name) }} Plan
        </b-badge>
      </b-card-header>
      <b-card-body>
        <ul class="list-unstyled my-1">
          <li>
            <span class="align-middle">Price: €{{ getPrice(billing.pay_months, getPackage(billing.package)) }}/mo {{ billing.pay_months === 1 ? '' : `for ${billing.pay_months} months` }}</span>
          </li>
          <li
            v-if="isNewPackage && packageType===7003"
            class="my-25"
          >
            5000 conversions + 5 million impressions (clicks) included
          </li>
          <li
            v-if="isNewPackage && packageType===7004"
            class="my-25"
          >
            20.000 conversions + 15 million impressions (clicks) included
          </li>
          <li
            v-if="isNewPackage && packageType===7005"
            class="my-25"
          >
            50.000 conversions + 25 million impressions (clicks) included
          </li>
          <li
            v-if="isNewPackage && packageType===7006"
            class="my-25"
          >
            250.000 conversions + 50 million impressions (clicks) included
          </li>
          <li
            v-if="isNewPackage && packageType===5003"
            class="my-25"
          >
            20,000 Signups and Deposits + 20 million impressions (clicks) included
          </li>
          <li
            v-if="isNewPackage && packageType===6003"
            class="my-25"
          >
            50,000 Signups and Deposits + 50 million impressions (clicks) included
          </li>
          <li
            v-if="isNewPackage===false"
            class="my-25"
          >
            <span class="align-middle">Charged For: <span class="text-capitalize">{{ billing.paymode }}</span></span>
          </li>
          <li
            v-if="isNewPackage===false"
            class="my-25"
          >
            <span
              v-if="billing.paymode === 'conversions'"
              class="align-middle"
            >Monthly Conversions: {{ numberWithCommas(parseInt(getPackage(billing.package).conversions)) }}</span>
            <span
              v-else
              class="align-middle"
            >Monthly Clicks: {{ numberWithCommas(parseInt(getPackage(billing.package).clicks)) }}</span>
          </li>
          <li>
            <span
              v-if="billing.discount > 0"
              class="align-middle"
              style="margin-top: 10px;"
            > <feather-icon
              v-b-modal.discount-history-modal
              icon="InfoIcon"
              size="16"
              class="align-middle"
              title="Show discount history"
              style="cursor: pointer;"
            />
              Discount: € {{ parseFloat(billing.discount).toFixed(2) }}
            </span>
          </li>
          <li>
            <span
              v-if="billing.discount_start && billing.discount > 0"
              class="align-middle"
            >&nbsp;&nbsp;&nbsp;&nbsp;
              Discount start: {{ billing.discount_start }}</span>
          </li>
          <li>
            <span
              v-if="billing.discount_end && billing.discount > 0"
              class="align-middle"
            >&nbsp;&nbsp;&nbsp;&nbsp;
              Discount end: {{ billing.discount_end }}</span>
          </li>

          <b-badge
            v-if="featuresData.length > 0"
            variant="light-primary"
            class="mt-2 font-small-4"
          >
            Optional features enabled
          </b-badge>
          <div v-if="featuresData.length < 3 && featuresData.length != 0">
            <li type="circle">
              <span>{{ featuresData[0].invoice_row_text }}</span>
            </li>
            <li
              v-if="featuresData[1]"
              type="circle"
            >
              <span>{{ featuresData[1].invoice_row_text }}</span>
            </li>
          </div>
          <div v-else>
            <!-- eslint-disable -->
            <li
              v-for="(feature, index) in featuresData"
              v-if="showAllFeatures || index < 2"
              :key="index"
              type="circle"
            >
              <span
                v-if="index == 1 && !showAllFeatures"
                class="align-middle"
              >
                ...
                <b-badge
                  v-if="featuresData.length > 2 && !showAllFeatures"
                  variant="light-primary"
                  style="padding: 3px 5px; cursor: pointer;"
                  @click="showAllFeatures = !showAllFeatures"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="20"
                  />
                </b-badge>
              </span>
              <span
                v-else
                class="align-middle"
              >{{ feature.invoice_row_text }}</span>
            </li>
          </div>
        </ul>
      </b-card-body>
    </div>
    <!-- Pro Forma Invoices -->
    <b-modal
      id="discount-history-modal"
      ref="discountHistoryModal"
      cancel-variant="outline-secondary"
      centered
      title="Discount History"
      size="lg"
      ok-only
    >
      <vue-good-table
        v-if="discountHistories"
        :columns="columns"
        :rows="discountHistories"
        class="mt-2"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Common -->
          <span>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-modal>

  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from '@/libs/axios'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    VueGoodTable,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    billing: {
      type: Object,
      default: null,
    },
    discountHistories: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      package: null,
      isNewPackage: false,
      packageType: 0,
      columns: [
        {
          label: 'Billing ID',
          field: 'billing_id',
        },
        {
          label: 'Discount Start',
          field: 'discount_start',
        },
        {
          label: 'Discount End',
          field: 'discount_end',
        },
        {
          label: 'Discount Price',
          field: 'discount_amount',
        },
        {
          label: 'Added On',
          field: 'added_on',
        },
      ],
      featuresData: [],
      showAllFeatures: false,
    }
  },
  computed: {
    getPackage() {
      const pack = uid => this.$store.getters['auth/getPackageByUID'](uid)
      return pack
    },
  },
  mounted() {
    this.packageType = Number(this.billing.package)
    if ((this.packageType >= 7003 && this.packageType <= 7006) || this.packageType === 5003 || this.packageType === 6003) this.isNewPackage = true
    this.fetchOptionalFeatures()
  },
  methods: {
    async fetchOptionalFeatures() {
      axios.get(`api/optional_feature/get_features_user/${this.$route.params.id}`).then(response => {
        if (response?.data?.length > 0) {
          this.featuresData = response.data
        } else {
          this.featuresData = []
        }
      }).catch(error => {
        console.log(error)
      })
    },

    titleCase(str) {
      const splitStr = str.toLowerCase().split(' ')
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
      }
      return splitStr.join(' ')
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

    getPrice(payMonths, pc) {
      let price = ''

      // eslint-disable-next-line radix
      switch (parseInt(payMonths)) {
        case 12:
          price = pc.price4
          break
        case 6:
          price = pc.price3
          break
        case 3:
          price = pc.price2
          break
        default:
          return pc.price1
      }

      return price
    },
  },
}
</script>
<style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
