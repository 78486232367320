<template>
  <b-card-actions
    title="Activity"
    tdesc="Logins, Messages, Changes"
    colapse="true"
    action-collapse
    class="user-custom-card"
  >
    <b-card-text>
      <h6 class="text-muted">
        <strong>Last 7 days stats</strong>
      </h6>
      <p
        v-if="last_7_days"
        class="mb-0 ml-1"
      >
        Clicks: {{ last_7_days.clicks }}
      </p>
      <p
        v-if="last_7_days"
        class="ml-1"
      >
        Conversions: {{ last_7_days.conversions }}
      </p>
      <p
        v-else
        class="mb-0 ml-1"
      >
        No stats on record.
      </p>

      <h6 class="text-muted">
        <strong>Last 30 days stats</strong>
      </h6>
      <p
        v-if="last_7_days"
        class="mb-0 ml-1"
      >
        Clicks: {{ last_30_days.clicks }}
      </p>
      <p
        v-if="last_7_days"
        class="ml-1"
      >
        Conversions: {{ last_30_days.conversions }}
      </p>
      <p
        v-else
        class="mb-0 ml-1"
      >
        No stats on record.
      </p>

      <h6 class="text-muted">
        <strong>LOGINS</strong>
      </h6>
      <div
        v-if="logins && logins.length > 0"
        class="mb-0 ml-1"
      >
        <div v-if="logins.length > 5">
          <p
            v-for="index in 5"
            :key="index"
          >
            {{ index == 0 ? 'Last Login: ' : '' }} {{ logins[index].created_at }} (GMT) from IP: {{ logins[index].ip }} ({{ logins[index].country_name }})
          </p>
        </div>
        <p v-else>
          Last Login: {{ logins[0].created_at }} (GMT) from IP: {{ logins[0].ip }} ({{ logins[0].country_name }})
        </p>
      </div>
      <p
        v-else
        class="mb-0 ml-1"
      >
        No logins on record.
      </p>
      <p class="ml-1">
        Total Logins: {{ logins && logins.length > 0 ? logins.length : 0 }}
      </p>
      <h6 class="mt-2 text-muted">
        <strong>MESSAGES</strong>
      </h6>
      <p class="mb-0 ml-1">
        Received by user: {{ messages ? messages.messages_in.length : '0' }} messages
      </p>
      <p class="ml-1">
        Sent by user: {{ messages ? messages.messages_out.length : '0' }} messages
      </p>
      <h6 class="mt-2 text-muted">
        <strong>CHANGES</strong>
      </h6>
      <div v-if="changes && changes.length > 0">
        <p
          v-for="item in changes"
          :key="item.id"
          class="mb-0 ml-1"
        >
          {{ formatDate(item.change_date) }} Changed: {{ item.change_param }} From: {{ item.value_old }} To: {{ item.value_new }}
        </p>
      </div>
      <p
        v-else
        class="ml-1"
      >
        No changes on record.
      </p>
      <b-button
        class="mt-2"
        variant="warning"
        size="sm"
        :disabled="disabled"
        @click="loadMore"
      >
        Load more
      </b-button>
    </b-card-text>
  </b-card-actions>
</template>

<script>
import {
  BCardText, BButton,
} from 'bootstrap-vue'
import BCardActions from '@/layouts/components/UserDetailCard.vue'
import axios from '@/libs/axios'
import moment from 'moment'

export default {
  components: {
    BCardActions,
    BCardText,
    BButton,
  },
  props: {
    activity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      logins: null,
      messages: null,
      changes: null,
      last_30_days: null,
      last_7_days: null,
      to_load: 50,
      disabled: false,
    }
  },
  created() {
    this.logins = this.activity.logins
    this.messages = this.activity.messages
    this.changes = this.activity.changes
    this.last_30_days = this.activity.last_30
    this.last_7_days = this.activity.last_7
  },
  methods: {
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },

    loadMore() {
      const payload = {
        to_load: this.to_load,
      }
      // eslint-disable-next-line radix
      const uid = parseInt(this.$route.params.id)
      axios.post(`/api/users/split_load_more/${uid}`, payload)
        .then(response => {
          if (response.status === 200) {
            // check if end_reached is true
            if (response.data.end_reached) {
              // disable button
              this.disabled = true
            }
            if (response.data.activity.changes.length === 0) {
              this.$bvToast.toast('No more changes to load.', {
                title: 'End of changes',
                variant: 'warning',
                solid: true,
              })
              this.disabled = true
              return
            }
            // push new data to existing data
            this.logins.push(...response.data.activity.logins)
            this.messages.messages_in.push(...response.data.activity.messages.messages_in)
            this.messages.messages_out.push(...response.data.activity.messages.messages_out)
            this.changes.push(...response.data.activity.changes)
            // filter out duplicates
            this.logins = this.logins.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            this.messages.messages_in = this.messages.messages_in.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            this.messages.messages_out = this.messages.messages_out.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            this.changes = this.changes.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            // increase to_load by 50
            this.to_load += 50
            // alert success
            this.$bvToast.toast('Loaded more data', {
              title: 'Success',
              variant: 'success',
              solid: true,
            })
          } else {
            // alert error
            this.$bvToast.toast('Error loading more data', {
              title: 'Error',
              variant: 'danger',
              solid: true,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
