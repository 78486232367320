<template>
  <b-tab
    title="Invoices"
    active
  >
    <div class="users-table">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        v-if="invoices"
        :columns="columns"
        :rows="invoices"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <span v-if="props.column.field === 'title'">
            <b-link :to="'/invoices/' + props.row.id">
              <span v-if="props.row.is_credit_note || props.row.btc_transfer">{{ `Pro Forma Invoice #${props.row.credit_note}` }}</span>
              <span v-else> {{ props.row.version === 1 ? `Invoice# ${props.row.invoice}` : `#TM${props.row.invoice_number_v2}` }}</span>
            </b-link>
          </span>

          <span v-else-if="props.column.field === 'date'">
            {{ formatDate(props.row.issue_date) }}
          </span>

          <span v-else-if="props.column.field === 'package'">
            {{ getPackage(props.row) }}
          </span>

          <span v-else-if="props.column.field === 'value'">
            {{ ` ${props.row.currency.toUpperCase()} ${parseFloat(props.row.invoice_value).toFixed(2)} (inc.VAT ${props.row.vat}%)` }}
          </span>

          <span v-else-if="props.column.field === 'period'">
            {{ `${formatDate(props.row.start_date)} - ${formatDate(props.row.end_date)}` }}
          </span>

          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(getInvoiceStatus(props.row.invoice_status))">
              {{ getInvoiceStatus(props.row.invoice_status) }}
            </b-badge>
          </span>

          <span v-else-if="props.column.field === 'credit_note'">
            <b-link
              v-if="props.row.is_credit_note === 1"
              @click="deleteCreditNote(props.row.credit_note)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-link>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap mt-1">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','25','50']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </b-tab>
</template>

<script>
import {
  BBadge,
  BFormGroup, BFormInput, BFormSelect, BLink,
  BPagination,
  BTab,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import moment from 'moment'

export default {
  components: {
    BTab,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BBadge,
    BLink,
  },
  props: {
    invoices: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Invoice',
          field: 'title',
          width: '130px',
        },
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Package',
          field: 'package',
        },
        {
          label: 'Value',
          field: 'value',
        },
        {
          label: 'Period',
          field: 'period',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Actions',
          field: 'credit_note',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Paid : 'light-success',
        Overdue     : 'light-danger',
        Pending      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    users() {
      return this.$store.state.users.trialUsers
    },
  },
  created() { },
  mounted() {
  },
  methods: {
    deleteCreditNote(creditNote) {
      this.confirmationAlert(`Are you sure you want to delete the pro forma invoice #${creditNote}?`)
        .then(res => {
          if (res.isConfirmed) {
            this.$store.dispatch('invoice/deleteCreditNote', creditNote)
            this.$emit('removeCreditNote', creditNote)
          }
        })
    },
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    getInvoiceStatus(status) {
      if (status === 'pending') return 'Pending'
      if (status === 'overdue') return 'Overdue'
      return 'Paid'
    },
    getPackage(inv) {
      let str = ''

      if (inv.type !== 4 && inv.type !== 3) str = inv.invoice_rows[0].item ?? 'Custom'
      else if (inv.type === 4) {
        str = inv.invoice_rows[0].item
      } else if (inv.overage === 'conversions') str = 'Overage Conversions'
      else str = 'Overage Clicks'

      return str
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.users-table {
  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }
}
</style>
