<template>
  <b-card-actions
    title="User Settings"
    tdesc="Configuration Details"
    action-collapse
    class="user-custom-card user-settings-card"
  >
    <b-card-text>
      <b-row>
        <b-col
          v-if="showUserSetting"
          cols="6"
          class="d-flex justify-content-start flex-column mobileScrn"
        >
          <h6 class="text-dark">
            <strong>Network Domain</strong>
          </h6>
          <p>{{ getDomain() }}</p>
          <h6 class="text-dark mt-1">
            <strong>Datacenter Location</strong>
          </h6>
          <p>{{ getDataCenterLocation(setting.datacenter_location) }}</p>
          <h6 class="text-dark mt-1">
            <strong>DNS</strong>
          </h6>
          <p>DNS Chosen: <span class="text-uppercase">{{ setting.dns_type }}</span></p>
        </b-col>
        <b-col
          v-if="showUserSetting"
          cols="6"
          class="mobileScrn"
        >
          <h6 class="text-dark">
            <strong>Sub-Domains</strong>
          </h6>
          <p class="mb-0 clh-26">
            Marketplace offers: <b>{{ setting.sub_offers }} </b><br>
            Smart tracking: <b>{{ setting.sub_smart }}</b><br>
            Admin sub-domain: <b>{{ setting.network_type === 'subdomain' ? '-' : setting.sub_admin }}</b><br>
            Banners sub-domain: <b>{{ setting.sub_banners }}</b><br>
            Static sub-domain: <b>{{ setting.sub_static }}</b><br>
            Postback sub-domain: <b>{{ setting.sub_postback }}</b><br>
            <span v-if="setting.short_domain">Short domain: <b>{{ setting.short_domain ? setting.short_domain : '' }}</b><br></span>
          </p>
          <b-button
            class="mt-2 mr-1"
            variant="primary"
            :disabled="sending"
            @click="resetSettings"
          >
            <span v-if="sending">
              <b-spinner
                small
                type="grow"
              />
              Reseting...
            </span>
            <span v-else>
              <feather-icon
                icon="RotateCwIcon"
                class="mr-50"
              />
              <span class="align-middle">Reset Settings</span>
            </span>

          </b-button>
          <b-button
            class="mt-2"
            variant="success"
            @click="accessPlatform"
          >
            <feather-icon
              icon="LinkIcon"
              class="mr-50"
            />
            <span>Access platform</span>
          </b-button>
        </b-col>
        <b-col
          v-if="!showUserSetting"
          cols="12"
        >
          <div style="padding:25px">
            <b-alert
              show
              variant="warning"
            >
              <div style="padding:25px">
                <p>
                  <feather-icon
                    icon="SettingsIcon"
                    size="12"
                    class="ch-title"
                  />
                  This user has no settings yet configured, inactive, or the settings may have been reset.
                </p>
              </div>
            </b-alert>
          </div>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card-actions>
</template>

<script>
import {
  BButton, BCardText, BCol, BRow, BAlert, BSpinner,
} from 'bootstrap-vue'
import BCardActions from '../../../layouts/components/UserDetailCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BButton,
    BCardText,
    BAlert,
    BSpinner,
  },
  props: {
    settings: {
      type: Object,
      default: null,
    },
    showUserSetting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sending: false,
      package: null,
      setting: null,
    }
  },
  created() {
    this.setting = this.settings
  },
  methods: {
    async accessPlatform() {
      const setting = await this.$store.dispatch('setting/fetchSetting', this.setting.uid)
      if (setting.network_type === 'domain') {
        this.url = `https://${setting.sub_admin}.${setting.domain}`
      } else {
        this.url = `https://${setting.subdomain}.trafficmanager.com`
      }
      window.open(this.url, '_blank')
    },

    getDomain() {
      let domain = ''

      if (this.setting.network_type === 'domain') {
        if (this.setting.domain) domain = `Domain: ${this.setting.domain}`
        else domain = 'Domain NOT defined, yet.'
      } else if (this.setting.subdomain) domain = `Domain: ${this.setting.subdomain}.trafficmanager.com`
      else domain = 'Domain NOT defined, yet.'

      return domain
    },
    getDataCenterLocation(location) {
      let loc = ''

      // eslint-disable-next-line radix
      switch (parseInt(location)) {
        case 1:
          loc = 'Europe'
          break
        case 2:
          loc = 'America (US East Coast)'
          break
        case 3:
          loc = 'America (US West Coast)'
          break
        case 4:
          loc = 'Asia'
          break
        default:
          loc = 'Europe'
          break
      }

      return loc
    },
    resetSettings() {
      this.sending = true
      this.$store.dispatch('setting/resetSettings', this.setting.uid)
        .then(res => {
          if (res) {
            this.sending = false
            this.setting = res
            this.notify({
              text: 'User settings has been reset successfully!',
              variant: 'success',
            })
          }
          // Refresh the page
          setTimeout(() => {
            window.location.reload()
          }, 1250)
        })
    },
  },
}
</script>
<style>

</style>
