<template>
  <b-card-actions
    title="Tickets"
    tdesc="Support Requests"
    colapse="true"
    action-collapse
    class="user-custom-card"
  >
    <b-card-text>
      <h6 class="text-muted">
        <strong>Tickets Open</strong>
      </h6>
      <p
        v-if="openTicket && openTicket.length > 0"
        class="mb-0 ml-1"
      >
        <a
          :href="'/tickets/open-tickets?uid='+uid"
          class="font-weight-bold"
        >View {{ openTicket.length }} Open Tickets</a>
      </p>
      <p
        v-else
        class="ml-1"
      >
        No open support tickets, so far.
      </p>
      <h6 class="mt-2 text-muted">
        <strong>Tickets Closed</strong>
      </h6>
      <p
        v-if="closeTicket && closeTicket.length > 0"
        class="mb-0 ml-1"
      >
        <a
          :href="'/tickets/closed-tickets?uid='+uid"
          class="font-weight-bold"
        >View {{ closeTicket.length }} Closed Tickets</a>
      </p>
      <p
        v-else
        class="ml-1"
      >
        No closed support tickets, so far.
      </p>
    </b-card-text>
  </b-card-actions>
</template>

<script>
import {
  BCardText,
} from 'bootstrap-vue'
import BCardActions from '@/layouts/components/UserDetailCard.vue'

export default {
  components: {
    BCardActions,
    BCardText,
  },
  props: {
    tickets: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      openTicket: null,
      closeTicket: null,
      uid: '',
    }
  },
  created() {
    this.uid = this.$route.params.id
    if (this.tickets) {
      if (this.tickets.open) this.openTicket = this.tickets.open
      if (this.tickets.closed) this.closeTicket = this.tickets.closed
    }
  },
  methods: { },
}
</script>
