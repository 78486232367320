<template>

  <div
    class="invoice-table"
    :show="!rows"
  >
    <!-- table -->
    <vue-good-table
      v-if="rows"
      :columns="columns"
      :rows="rows"
      :sort-options="{
        enabled: false,
      }"
      :search-options="{
        enabled: false
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: status -->
        <span v-if="props.column.field === 'ticket_status'">
          <b-badge
            v-if="props.row.ticket_status === 'open'"
            variant="primary"
          >
            Open
          </b-badge>
          <b-badge
            v-else-if="props.row.ticket_status === 'waiting'"
            variant="info"
          >
            Waiting
          </b-badge>
          <b-badge
            v-else-if="props.row.ticket_status === 'in_progress'"
            variant="warning"
          >
            In progress
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'action'">
          <router-link
            :to="`/tickets/${props.row.id}`"
          >Reply</router-link>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

    </vue-good-table>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'

export default {
  components: {
    VueGoodTable,
    BBadge,
  },
  props: {
    tickets: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'Category',
          field: 'ticket_type',
        },
        {
          label: 'Subject',
          field: 'ticket_subject',
        },
        {
          label: 'Status',
          field: 'ticket_status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  mounted() {
    this.fetchToReplyRec()
  },
  methods: {
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    fetchToReplyRec() {
      this.tickets.forEach(item => {
        this.rows.push(item)
      })
    },
  },
}
</script>

  <style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';

  .invoice-table {
    thead tr{
      background: #f3f2f7;
    }

    th, td {
      font-size: 14px;
      text-align: center !important;
      vertical-align: middle !important;
    }
  }
  </style>
