<template>
  <b-tab
    title="Payments"
  >
    <div class="users-table">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        v-if="payments"
        :columns="columns"
        :rows="payments"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <span v-if="props.column.field === 'domain'">
            <a
              href="javascript:void(0)"
            >{{ getDomain(props.row) }}</a>
          </span>

          <span v-else-if="props.column.field === 'amount'">
            {{ getValue(props.row) }}
          </span>

          <span v-else-if="props.column.field === 'date'">
            {{ formatDate(props.row.payment_timestamp) }}
          </span>

          <span v-else-if="props.column.field === 'method'">
            {{ getMethod(props.row.paidWith) }}
          </span>

          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(getStatus(props.row.result))">
              {{ getStatus(props.row.result) }}
            </b-badge>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap mt-1">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','25','50']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </b-tab>
</template>

<script>
import {
  BBadge,
  BFormGroup, BFormInput, BFormSelect,
  BPagination,
  BTab,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import moment from 'moment'

export default {
  components: {
    BTab,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BBadge,
  },
  props: {
    payments: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Domain',
          field: 'domain',
        },
        {
          label: 'Amount',
          field: 'amount',
        },
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Method',
          field: 'method',
        },
        {
          label: 'Status',
          field: 'status',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Paid : 'light-success',
        Failed     : 'light-danger',
        Canceled     : 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() { },
  methods: {
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    getDomain(user) {
      if (user.network_type === 'domain') {
        if (user.domain) return user.domain
        return 'N/A'
      }

      if (user.network_type === 'subdomain') {
        if (user.subdomain) return `${user.subdomain}.trafficmanager.com`
        return 'N/A'
      }

      return 'N/A'
    },
    getValue(user) {
      let str = ''

      if (user.payment_value) {
        if (user.invoice_model) str = user.invoice_model.currency.toUpperCase()
        else str = '€'
        if (str === 'RON') {
          str += ` ${parseFloat(user.payment_value / user.invoice_model.exchange_rate).toFixed(2)}`
        } else {
          str += ` ${parseFloat(user.payment_value).toFixed(2)}`
        }
      } else str = 'N/A'

      return str
    },
    getMethod(pw) {
      if (pw === 'creditCard' || pw === 'credit_card') return 'Credit Card'
      if (pw === 'payPal') return 'PayPal'
      if (pw === 'wire') return 'Wire Transfer'
      if (pw === 'Referral ballance applied.') return 'Referral balance applied'
      if (pw === 'crypto') return 'Crypto Payment'
      if (pw === 'Paid - Admin') return 'Paid - Admin'
      if (pw === 'Stripe') return 'Stripe'
      return 'Unknown'
    },
    getStatus(result) {
      if (result === 'canceled') return 'Canceled'
      if (result === 'paid') return 'Paid'
      return 'Failed'
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.users-table {
  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }
}
</style>
