<template>
  <b-tab
    :key="refreshKey"
    title="Custom Interventions"
  >
    <div class="users-table">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-between">
        <div>
          <b-button
            v-b-modal.intervention-modal
            variant="primary"
          >
            <feather-icon icon="PlusIcon" />
            Add
          </b-button>
        </div>

        <!-- Custom intervention modal -->
        <b-modal
          id="intervention-modal"
          ref="modal"
          cancel-variant="outline-secondary"
          ok-only
          ok-title="Apply"
          centered
          title="Add custom intervention"
          no-close-on-backdrop
          @ok="handleOk"
        >
          <b-form ref="form">
            <b-form-group
              label="Name"
              label-for="name"
              invalid-feedback="Name is required"
            >
              <b-form-input
                id="name"
                v-model="intervention.name"
                placeholder="Custom intervention name"
                :state="nameState"
                required
              />
            </b-form-group>
            <b-form-group
              label="Price"
              label-for="price"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  EUR
                </b-input-group-prepend>
                <b-form-input
                  id="price"
                  v-model="intervention.price"
                  type="number"
                  :state="priceState"
                  required
                />
              </b-input-group>
            </b-form-group>

          </b-form>
        </b-modal>
        <!-- Custom intervention modal update -->
        <b-modal
          id="intervention-modal-update"
          ref="modal"
          cancel-variant="outline-secondary"
          ok-only
          ok-title="Update"
          centered
          title="Update custom intervention"
          no-close-on-backdrop
          @ok="handleOk2"
        >
          <b-form ref="form">
            <b-form-group
              label="Name"
              label-for="name"
              invalid-feedback="Name is required"
            >
              <b-form-input
                id="name"
                v-model="intervention.name"
                placeholder="Custom intervention name"
                :state="nameState"
                required
              />
            </b-form-group>
            <b-form-group
              label="Price"
              label-for="price"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  EUR
                </b-input-group-prepend>
                <b-form-input
                  id="price"
                  v-model="intervention.price"
                  type="number"
                  :state="priceState"
                  required
                />
              </b-input-group>
            </b-form-group>

          </b-form>
        </b-modal>

        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <!-- table -->
      <vue-good-table
        v-if="ciData"
        :columns="columns"
        :rows="ciData"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: false,
          perPage : 25,
        }"
      >

        <!-- <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label =='Name'" class="d-flex flex-row flex-wrap text-left pl-1 w-auto">
            {{ props.column.label }}
          </span>
        </template> -->

        <template
          slot="table-row"
          slot-scope="props"
        >

          <span v-if="props.column.field === 'price'">
            {{ getValue(props.row.price) }}
          </span>

          <span
            v-if="props.column.field === 'name'"
            class="d-flex flex-row flex-wrap text-left pl-1 pr-0 w-auto"
          >
            {{ props.row.name }}
          </span>

          <span v-else-if="props.column.field === 'created_at'">
            {{ formatDate(props.row.created_at) }}
          </span>

          <span
            v-else-if="props.column.field === 'id'"
            style="display: flex; align-items: center; justify-content: center; gap:20px;"
          >
            <b-link
              @click="deleteCI(props.row.id, props.row.name)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-link>
            <b-link
              v-b-modal.intervention-modal-update
              @click="updateData(props.row.id, props.row.name,props.row.price)"
            >
              <feather-icon icon="Edit3Icon" />
            </b-link>
          </span>

        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap mt-1">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','25','50']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </b-tab>
</template>

<script>
import {
  BButton, BForm,
  BFormGroup, BFormInput, BFormSelect, BInputGroup, BInputGroupPrepend, BLink,
  BPagination,
  BTab,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import axios from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTab,
    VueGoodTable,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BLink,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
  },
  props: {
    ci: {
      type: Array,
      default: null,
    },
    user: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      intervention: {
        name: null,
        price: 0,
      },
      updateModal: {
        id: null,
        name: null,
      },
      nameState: null,
      priceState: null,
      pageLength: 10,
      refreshKey: 0,
      dir: false,
      ciData: null,
      userData: null,
      columns: [
        {
          label: 'Name',
          field: 'name',
          width: '30vw',
        },
        {
          label: 'Price',
          field: 'price',
        },
        {
          label: 'Date created',
          field: 'created_at',
        },
        {
          label: 'Actions',
          field: 'id',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  created() {
    this.ciData = this.ci
    this.userData = this.user
  },
  methods: {
    checkFormValidity() {
      this.nameState = this.intervention.name !== null && this.intervention.name.length > 0
      this.priceState = /^-?\d*\.?\d+$/.test(this.intervention.price) && this.intervention.price !== 0
      if (this.nameState && this.priceState) {
        return true
      }
      return false
    },
    updateData(id, name, price) {
      this.updateModal.id = id
      this.updateModal.name = name
      this.intervention.price = price
      this.intervention.name = name
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      // actual submit
      axios.post('/api/custom-interventions', {
        name: this.intervention.name,
        price: this.intervention.price,
        user_id: this.userData.user_id,
      }).then(res => {
        this.$nextTick(() => {
          this.nameState = null
          this.priceState = null
          this.$refs.modal.hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: 'Custom intervention saved',
              variant: 'success',
            },
          })
          // add to the table
          this.ciData.push(res.data)
          // refresh the table to show the new data added
          this.ciData = this.ciData.map(obj => obj)
        })
      })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    getValue(price) {
      let str = '€'
      str += ` ${parseFloat(price).toFixed(2)}`
      return str
    },
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    deleteCI(id, name) {
      this.confirmationAlert(`Are you sure you want to delete ${name}?`)
        .then(res => {
          if (res.isConfirmed) {
            axios.delete(`/api/custom-interventions/${id}`).then(() => {
              this.ciData = this.ciData.filter(obj => obj.id !== id)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: 'A custom intervention was removed',
                  variant: 'success',
                },
              })
            })
          }
        })
    },
    handleOk2(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.updateCI(this.updateModal.id, this.updateModal.name)
    },
    updateCI(id, name) {
      const payload = {
        name: this.intervention.name,
        price: this.intervention.price,
        id,
      }
      if (!this.checkFormValidity()) {
        return
      }
      this.confirmationAlert(`Are you sure you want to update ${name}?`)
        .then(res => {
          if (res.isConfirmed) {
            this.nameState = null
            this.priceState = null
            axios.patch(`/api/custom-interventions/${id}`, payload).then(() => {
              this.$refs.modal.hide()
              this.refreshKey += 1
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: 'A custom intervention was updated',
                  variant: 'success',
                },
              })
              /// refresh the table component and the data
              this.ciData = this.ciData.map(obj => {
                if (obj.id === id) {
                  return payload
                }
                return obj
              })
            })
          }
        })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.users-table {
  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }
}
</style>
