<template>
  <b-card>
    <b-row
      v-if="user"
      class="justify-evenly"
    >
      <b-col
        class="head1"
        style="min-width: 345px;"
      >
        <div style="min-width: 345px;">
          <b-avatar
            :src="avatar"
            size="104px"
            rounded
            class="headAvatar"
          />
          <div
            class="headPInfo"
            style="width:240px;"
          >
            <h3 class="mb-0 text-capitalize">
              {{ `${user.forename} ${user.surname}` }}
            </h3>
            <span
              v-if="user.company"
              class="card-text mb-0"
            >
              <span>{{ user.company }}</span><br>
              <span v-if="user.vatno"><strong>VAT #:</strong> {{ user.vatno }}</span><br>
              <span v-if="user.regno"><strong>Reg #:</strong> {{ user.regno }}</span><br>
            </span>
            <span
              v-else
              class="card-text mb-0"
            >
              <small>Individual Account</small><br>
            </span>
            <span
              v-if="user.referredBy"
              class="card-text mb-0"
            >
              <strong>Referred By:</strong> <b-link
                :to="`/user/details/${user.referredBy.uid}`"
                target="_blank"
              >{{ user.referredBy.email }}</b-link>
            </span>
            <span
              v-else-if="user.cpaReferredBy"
              class="card-text mb-0"
            >
              <strong>Referred By:</strong> <b-link
                :to="`/user/details/${user.cpaReferredBy.uid}`"
                target="_blank"
              >{{ user.cpaReferredBy.email }}</b-link>
            </span>
            <!-- <span
              v-else-if="user.ed.referredBy && user.ed.referredBy !== '' && user.ed.refferedBy !== undefined && user.ed.referredBy !== null"
              class="card-text mb-0"
            >
              <strong>Referred By: <span style="color:blueviolet;">{{ user.ed.referredBy }} (External)</span></strong>
            </span> -->
            <span
              v-else-if="referredByExternal"
              class="card-text mb-0"
            >
              <strong>Referred By: <span style="color:blueviolet;">{{ referredByExternal }} (External)</span></strong>
            </span>
            <br>
            <span
              v-if="((!user.sub_start || !user.trial_start))"
              class="card-text mb-0"
            >
              <span v-if="user.active === 1"><strong>Registered On: </strong></span>
              <span v-else><strong>Reg. Attempt On: </strong></span>
              {{ user.active === 1 ? formatDate(user.created_at) : formatDate(user.created_at) }}
            </span><br>
            <span
              class="card-text mb-0"
            >
              <strong>Current Status:</strong>
              <span :style="user.user_status == 9 ? 'color:green;font-weight:bold;margin-left:4px;' : user.user_status == 10 ? 'color:darkorange;font-weight:bold;margin-left:4px;' : 'margin-left:4px;' ">{{ userStatus(user) }}</span>
            </span><br>
            <span v-if="user.user_status == 7 && extendedExpiryDate">
              <strong>Notice:</strong> Will be suspended on {{ extendedExpiryDate }}<br></span>
            <div v-if="user.backup_start && user.user_status === 8">
              <p
                v-if="user.backup_start"
                class="card-text mb-0"
              >
                <strong>Backup Start Date:</strong><br>{{ formatDate(user.backup_start) }}
              </p>
              <p
                v-if="user.backup_end"
                class="card-text mb-0"
              >
                <strong>Backup End Date:</strong><br>{{ formatDate(user.backup_end) }}
              </p>
              <br>
            </div>
            <span
              v-if="user.user_status != 9"
              class="card-text mb-0 mt-3"
            >
              <strong style="margin-right: 3px;">DPA Agreement: </strong>
              <b-badge
                v-if="customer.hasSigned"
                class="badge bg-light-success"
                style="font-size: 13px;"
              >Signed</b-badge>
              <b-badge
                v-else
                class="badge bg-light-danger"
                style="font-size: 13px;"
              >Not signed</b-badge>
            </span>
            <p v-if="user.user_status != 9">
              <button
                v-if="customer.hasSigned"
                type="button"
                style="padding: 7px; margin-top: 5px;"
                class="btn btn-success"
                variant="primary"
                :disabled="downloading"
                @click="openSelectDPA"
              ><span v-if="downloading">
                 <b-spinner
                   small
                   type="grow"
                 />
                 Downloading...
               </span>
                <span v-else>Download Agreement</span>
              </button>
            </p>
          </div>
        </div>
      </b-col>
      <b-col
        class="head2"
        cols="4"
      >
        <div v-if="(user.sub_start || user.trial_start) ">
          <p
            v-if="user.sub_start"
            class="card-text mb-0"
          >
            <strong>Subscribed On:</strong><br>{{ formatDate(user.sub_start) }}
          </p>
          <p
            v-if="user.trial_start"
            class="card-text mb-0"
          >
            <strong>Trial Started On:</strong><br>{{ formatDate(user.trial_start) }}
          </p>
          <p
            v-if="user.trial_start"
            class="card-text mb-0"
          >
            <strong>Trial Ended On:</strong><br>{{ formatDate(user.trial_end) }}
          </p>
        </div>
        <div>
          <p class="card-text mb-0 text-capitalize">
            <feather-icon
              icon="MapPinIcon"
              class="mr-50"
            /> {{ user.address1 }}
          </p>
          <p class="card-text mb-0 cmtx-5 text-capitalize">
            <feather-icon
              icon="MapPinIcon"
              class="mr-50"
            /> {{ `${user.city}${user.postcode != null ? ' ' + user.postcode:''}, ${getCountry(user.country)}` }}
          </p>
          <p class="card-text mb-0 cmtx-5">
            <feather-icon
              icon="MailIcon"
              class="mr-50"
            /> {{ user.email }}
          </p>
          <p class="card-text mb-0 cmtx-5">
            <feather-icon
              icon="PhoneIcon"
              class="mr-50"
            /> {{ user.phone ? user.phone : '' }}
          </p>
        </div><br>
      </b-col>
      <b-col
        class="head3"
        cols="2"
      >
        <b-button
          class="mb-1 customWidthB"
          variant="primary"
          @click="replyOrNewTicket"
        >
          <feather-icon
            icon="MailIcon"
            class="mr-50"
          />
          <span class="align-middle"><small>Send ticket</small></span>
        </b-button>

        <b-button
          v-if="user.user_status !== 9"
          class="mb-1 customWidthB"
          variant="primary"
          @click="impersonate"
        >
          <feather-icon
            icon="UsersIcon"
            class="mr-50"
          />
          <span class="align-middle"><small>Impersonate</small></span>
        </b-button>

        <b-button
          v-if="user.user_status === 3 && !user.trial_start && user.hasTrial"
          class="customWidthB"
          :disabled="activating"
          variant="primary"
          @click="activate"
        >
          <feather-icon
            icon="PowerIcon"
            class="mr-50"
          />
          <span class="align-middle"><small>Activate</small></span>
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="ticket-to-reply-modal"
      cancel-variant="outline-secondary"
      centered
      title="User's Tickets - requires action"
      size="lg"
      ok-title="Create new tickets"
      @ok="createNewTickets"
    >
      <TicketAction :tickets="ticketsToReply" />
    </b-modal>
    <b-modal
      id="change-dpa-modal"
      cancel-variant="outline-secondary"
      centered
      title="Select the DPA to download"
      size="lg"
      ok-title="Download"
      @ok="downloadDPA"
    >
      <b-form-group
        v-if="customer.hasSigned"
        style="font-size: 16px; font-weight: 600; margin-top: 5px;"
      >
        <div style="display: grid; align-items: center;">
          <b-form-select
            v-model="templateDPA"
            :options="allDPA"
            @change="changeDownloadDPA"
          />
          <div style="width: fit-content; margin-top: 5px;">
            <b-button
              variant="btn btn-info"
              class="btn-sm"
              :disabled="generate"
              @click="generatePDF"
            >
              <span v-if="generate">
                <b-spinner
                  small
                  type="grow"
                />
                Generating...
              </span>
              <span v-else>Generate PDF</span>
            </b-button>
            <b-button
              variant="btn btn-danger"
              class="btn-sm"
              style="margin-left: 5px;"
              @click="deleteDPA"
            >
              Delete
            </b-button>
          </div>
        </div>
      </b-form-group>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BLink, BRow, BCol, BSpinner, BFormGroup,
  BFormSelect, BBadge,
} from 'bootstrap-vue'
import moment from 'moment'
import axios from '@/libs/axios' // eslint-disable-line
import TicketAction from './TicketAction.vue'

export default {
  components: {
    BCard,
    BButton,
    BAvatar,
    BLink,
    BRow,
    BCol,
    BBadge,
    BSpinner,
    BFormGroup,
    BFormSelect,
    TicketAction,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    // eslint-disable-next-line vue/require-default-prop
    avatar: null,
  },
  data() {
    return {
      activating: false,
      ticketsToReply: false,
      customer: {
        uid: 0,
        name: 'Customer Name',
        company: 'Customer Company',
        title: 'Customer Title',
        position: 'Customer Position',
        address: 'Customer Address',
        signature: '',
        hasSigned: false,
        dateSigned: '',
      },
      allDPA: [],
      templateDPA: 1,
      generate: false,
      downloading: false,
      referredByExternal: null,
    }
  },
  computed: {
    isPhone: {
      get() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) return true
        return false
      },
    },
    extendedExpiryDate() {
      if (this.user && this.user.ed && this.user.ed.expired_at) {
        const date = new Date(this.user.ed.expired_at)
        date.setDate(date.getDate() + 14)
        return date.toISOString().split('T')[0] // format it as 'YYYY-MM-DD'
      }
      return null // or return a default value or an empty string
    },
  },
  created() {
    this.fillCustomerInfo()
    this.referredByExternal = this.user?.ed?.referredBy ?? null
  },
  methods: {
    /* eslint-disable */
    async fillCustomerInfo()
    {
      const uid = this.$route.params.id;
      const response = await axios.get(`/api/dpa/get_last_signed_dpa/${uid}`)

      if (response.data.signed === true) {
        const info = response.data.info
        this.customer.name = info.full_name
        this.customer.company = info.company
        this.customer.title = info.title
        this.customer.address = info.address
        this.customer.signature = info.signature
        this.customer.dateSigned = info.created_at
        this.customer.hasSigned = true
        this.customer.agreeDPA = true
      } else {
        this.customer.hasSigned = false
      }

      if (this.customer.company != this.user.company) this.customer.hasSigned = false;
      if (this.customer.dateSigned) this.getCustomerAllDPA(uid);
    },

    openSelectDPA() {
      this.$bvModal.show('change-dpa-modal');
    },

    async getCustomerAllDPA(uid)
    {
      const response = await axios.get(`/api/dpa/get_user_all_dpa/${uid}`);

      if (response) {
        response.data.forEach((dpa, i) => {
          const dpaDate = new Date(dpa.created_at).toLocaleDateString("en-GB",
            { year: 'numeric', month: 'long', day: 'numeric' }
          );
          const dpaType = dpa.type === 'client_agreement' ? 'client agreement' : 'dpa';
          const obj = {
            ...dpa,
            text: `${dpa.company.replaceAll(" ", "_")}.pdf (${dpaDate}) (${dpaType})`,
            value: i + 1
          }
          this.allDPA.push(obj);
        });
        this.templateDPA = 1;
      }
      else this.customer.hasSigned = false;
    },

    async generatePDF() {
      this.generate = true;
      const idDPA = this.templateDPA - 1;
      const payload = {
        full_name: this.allDPA[idDPA].full_name,
        title: this.allDPA[idDPA].title,
        address: this.allDPA[idDPA].address,
        company: this.allDPA[idDPA].company,
        signature: this.allDPA[idDPA].signature,
        date: this.allDPA[idDPA].created_at,
        type: this.allDPA[idDPA].type
      };
      this.notify({
        text: 'We have started generating your PDF. You will be notified when it is ready.',
        variant: 'info',
      });
      axios.post("/api/dpa/generate_pdf", payload).then((res) => {
        if (res?.status === 200) {
          this.$bvToast.toast('The PDF has been generated, now you can download it.', {
            title: 'PDF Generated Successfully',
            variant: 'success',
            solid: true,
            autoHideDelay: 10000,
          });
        }
        else {
          this.$bvToast.toast('The PDF could not be generated successfully! Please create a ticket !', {
            title: 'PDF Generated',
            variant: 'danger',
            solid: true,
            autoHideDelay: 10000,
          });
        }
        this.generate = false;
      });
    },

    async downloadDPA() {
      this.downloading = true;
      const idDPA = this.templateDPA - 1;
      const payload = {
        name: this.allDPA[idDPA].full_name,
        company: this.allDPA[idDPA].company,
        date: this.allDPA[idDPA].created_at,
        type: this.allDPA[idDPA].type
      };
      await axios.post(`/api/dpa/download`, payload, { responseType: 'blob' }).then(res => {
        if (!res || !res?.data) {
          this.$bvToast.toast('The PDF for this DPA has not been generated by the server you can generate it manually !', {
            title: 'PDF Generated',
            variant: 'danger',
            solid: true,
            autoHideDelay: 10000,
          });
          this.downloading = false;
          return;
        }
        const fileName = payload.type === 'client_agreement' ? 'Client_Agreement' : 'DPA_Agreement';
        const link = document.createElement('a');
        const pdf_url = URL.createObjectURL(res.data);
        link.setAttribute('href', pdf_url);
        link.setAttribute('download', `${this.customer.name.replaceAll(" ", "_")}_${fileName}.pdf`);
        link.style.visibility = 'hidden';
        link.id = 'downloadPDF';
        document.body.appendChild(link);
        document.getElementById(link.id).click();

        const element = document.getElementById(link.id);
        element.remove();
      });
      this.downloading = false;
    },

    deleteDPA() {
      if(this.allDPA.length == 0) return this.customer.hasSigned = false;

      this.confirmationAlert('Are you sure you want to delete this DPA?')
        .then(async res => {
          if (res.isConfirmed) {
            const idDPA = this.templateDPA - 1;
            const payload = {
              uid: this.allDPA[idDPA].uid,
              id: this.allDPA[idDPA].id,
              name: this.allDPA[idDPA].full_name,
              company: this.allDPA[idDPA].company,
              date: this.allDPA[idDPA].created_at,
              type: this.allDPA[idDPA].type
            };
            const response = await axios.post(`/api/dpa/delete_dpa`, payload);
            if(!response) return;

            if(response.data.status == 'success') {
              this.allDPA.splice(idDPA, 1);
              this.allDPA = this.allDPA.map(e => {
                if(e.value > idDPA) e.value--;
                return e;
              });

              this.notify({
                text: response.data.msg,
                variant: 'success',
              });
              this.$bvModal.hide('change-dpa-modal');

              if(this.allDPA.length == 0) return this.customer.hasSigned = false;

              this.templateDPA = this.allDPA[0].value;
              this.changeDownloadDPA();
            }
            else {
              this.notify({
                text: response.data.msg,
                variant: 'danger',
              })
            }
            this.$bvModal.hide('change-dpa-modal')
          }
      });
    },

    changeDownloadDPA() {
      const idDPA = this.templateDPA - 1;
      this.customer.name = this.allDPA[idDPA].full_name;
      this.customer.company = this.allDPA[idDPA].company;
      this.customer.title = this.allDPA[idDPA].title;
      this.customer.address = this.allDPA[idDPA].address;
      this.customer.signature = this.allDPA[idDPA].signature;
      this.customer.dateSigned = this.allDPA[idDPA].created_at;
    },

    /* eslint-enable */
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    impersonate() {
      this.$store.dispatch('auth/impersonate', this.user.user_id).then(() => { window.location = '/' })
    },
    activate() {
      this.activating = true
      this.$store.dispatch('users/activate', this.user.user_id)
        .then(res => {
          this.$store.commit('users/UPDATE_USER', res.data.user)
          this.$emit('updateUser', res.data.user)
          this.$emit('updateUserInvoices', res.data.invoices)
          this.notify({
            text: 'User activated!',
            variant: 'success',
          })
        })
    },
    userStatus(user) {
      let status = ''

      if (user.is_affiliate === 1) status = 'Affiliate'
      else if (user.user_status === 1) status = 'Lead'
      else if (user.user_status === 2) status = 'Registered'
      else if (user.user_status === 3) status = 'Pending'
      else if (user.user_status === 4) status = 'Trial'
      else if (user.user_status === 5) status = 'Subscribed'
      else if (user.user_status === 6) status = 'Canceled'
      else if (user.user_status === 7) {
        status = 'Expired'
      } else if (user.user_status === 8) status = 'Backup'
      else if (user.user_status === 9) status = 'Admin'
      else if (user.user_status === 10) {
        if (user.ed && user.ed.suspended_by) {
          status = `Suspended | by ${user.ed.suspended_by}`
        } else {
          status = 'Suspended'
        }
      } else if (user.user_status === 0) status = 'Pending payment'
      return status
    },
    getCountry(code) {
      const country = this.$store.getters['auth/getCountryByCode'](code)

      if (country) return country.name
      return ''
    },
    replyOrNewTicket() {
      this.$store.dispatch('tickets/fetchToReply', this.user.uid)
        .then(res => {
          const cnt = Number(res.records.length)
          if (cnt === 0) {
            this.$router.push(`/tickets/new-ticket/${this.user.user_id}`)
          } else {
            this.ticketsToReply = res.records
            this.$bvModal.show('ticket-to-reply-modal')
          }
        })
    },
    createNewTickets() {
      this.$router.push(`/tickets/new-ticket/${this.user.user_id}`)
    },
  },
}
</script>
<style>

@media only screen and (min-width: 600px)  {
  .headAvatar{
    float: left;
    margin-left: -15px;
    padding-left: -15px;
  }
  .headPInfo{
    float: right;
    position:relative;
  }
  .customWidthB{
    width:121px !important;
    margin-left: -13px;
    padding-left: -13px;
  }
}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 450px)  {
  .head1{
    min-width:420px !important;
    text-align: center;
  }
  .headAvatar{
    float: none;
  }
  .headPInfo{
    float: none;
    text-align: center !important;
    position:none;
    margin:auto;
  }
  .head2{
    min-width:330px;
    text-align: center;
  }

  .head3{
    min-width:330px;
    padding-top:20px;
    text-align: center;
  }

  .customWidthB{
    width:100%
  }
}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 428px)  {
  .head1{
    min-width:420px !important;
    text-align: center;
  }
  .headAvatar{
    float: none;
  }
  .headPInfo{
    float: none;
    text-align: center !important;
    position:none;
    margin:auto;
  }
  .head2{
    min-width:330px;
    text-align: center;
  }

  .head3{
    min-width:330px;
    padding-top:20px;
    text-align: center;
  }

  .customWidthB{
    width:100%
  }
}

</style>
